import { useContext } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { AgencyContext } from '../App';
import Column from '../components/Column';

export interface AppLoadingProps {
    systemError: boolean;
}

export default function AppLoading(props: AppLoadingProps) {
    const agency = useContext(AgencyContext);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                gap: '20px',
                padding: '20px',
                maxWidth: '50%',
                width: '100%',
                margin: '0 auto',
            }}
        >
            {props.systemError ? (
                <>
                    <Column alignItems="center" justifyContent="center">
                        <img alt={agency?.name} src={'/Mascot_Astronaut_Floating_Laptop.svg'} style={{ height: 200 }} />

                        <img alt={agency?.name} src={'/Logo_Black_iCAN.svg'} style={{ height: 48 }} />

                        <CircularProgress color="info" />

                        <Typography variant="h5" fontWeight={700} gutterBottom>
                            Please wait while we perform system maintenance.
                        </Typography>
                    </Column>
                </>
            ) : (
                <>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{agency?.name || 'Please wait...'}</p>
                    <CircularProgress color="info" />
                </>
            )}
        </div>
    );
}
