import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteFreeSoloValueMapping,
    AutocompleteProps,
    AutocompleteValue,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    FilterOptionsState,
    FormControlLabel,
    Paper,
    MenuItem,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';

/**
 * Infinite Autocomplete Hook
 */

interface UseInfiniteAutocompleteState<
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    triggerOpen: () => void;
    triggerClose: () => void;
    isMassSelected: boolean;
    isMassLoading: boolean;
    toggleMassSelect: () => void;

    options: ReadonlyArray<Value>;
    computedOptions: Value[];

    setValue: (value: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>) => void;
    resetValue: () => void;

    isOptionEqualToValue: (_option: Value, _value: Value) => boolean;
    handleOnChange: (
        event: React.SyntheticEvent<Element, Event>,
        newValue: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>,
        reason: AutocompleteChangeReason
    ) => void;
    handleDisableOnFilterOptions: (options: Value[], state: FilterOptionsState<Value>) => Value[];
}

interface UseInfiniteAutocompleteProps<
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> {
    onFetchMass?: () => Promise<ReadonlyArray<Value>>;
    pageSize?: number;
    multiple?: boolean;
    options: ReadonlyArray<Value>;
    value: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>;
    onChange: (value: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>) => void;
    getOptionKey: (option: Value) => string | number;
    getOptionLabel: (option: Value) => string;
}

const useInfiniteAutocomplete = <
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>(
    props: UseInfiniteAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>
): UseInfiniteAutocompleteState<Value, Multiple, DisableClearable, FreeSolo> => {
    const { onFetchMass, pageSize = 20, multiple = false, value, onChange, getOptionKey } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<ReadonlyArray<Value>>(props.options);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    // Utilities

    const isOptionEqualToValue = (_option: Value, _value: Value): boolean => {
        return getOptionKey(_option) === getOptionKey(_value);
    };

    //

    const triggerOpen = useCallback((): void => {
        setIsOpen(true);
    }, []);

    const triggerClose = useCallback((): void => {
        setIsOpen(false);
    }, []);

    const computedOptions = useMemo((): Value[] => {
        const _values: Value[] = [];

        if (multiple && Array.isArray(value)) {
            _values.push(...value);
        } else {
            _values.push(value as Value);
        }

        if (isOpen) {
            return [..._values, ...options].filter(
                (selection, index, self) =>
                    self.findIndex((option: Value) => getOptionKey(option) === getOptionKey(selection)) === index
            );
        }

        return (_values.length ? _values : options.slice(0, pageSize)) as Value[];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, pageSize, multiple, value, options]);

    // Value

    const setValue = useCallback(
        (value: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>) => {
            onChange(value);
        },
        [onChange]
    );

    const resetValue = useCallback(() => {
        setValue((multiple ? [] : null) as AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>);
    }, [multiple, setValue]);

    // Handlers

    const handleDisableOnFilterOptions = (_options: Value[], _state: FilterOptionsState<Value>): Value[] => {
        return _options;
    };

    const handleOnChange = useCallback(
        (
            event: React.SyntheticEvent<Element, Event>,
            newValue: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>,
            reason: AutocompleteChangeReason
        ) => {
            onChange(newValue);
        },
        [onChange]
    );

    // Mass

    const [isMassLoading, setIsMassLoading] = useState<boolean>(false);

    const isMassSelected = useMemo((): boolean => {
        if (!multiple || !Array.isArray(options) || !Array.isArray(value) || options.length !== value.length) {
            return false;
        }

        const optionIds = new Set(options.map(getOptionKey));

        return value.every((selection: Value): boolean => optionIds.has(getOptionKey(selection)));
    }, [multiple, options, value, getOptionKey]);

    const toggleMassSelect = useCallback(async (): Promise<void> => {
        if (isMassSelected) {
            setValue([] as unknown as AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>);
        } else {
            try {
                if (onFetchMass) {
                    setIsMassLoading(true);
                    const options: ReadonlyArray<Value> = await onFetchMass();
                    setIsMassLoading(false);

                    setOptions(options);
                    setValue(options as AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>);
                } else {
                    setValue(options as unknown as AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>);
                }
            } catch (error) {
                console.error('Error fetching all options:', error);
            }
        }
    }, [isMassSelected, options, setValue, setOptions, setIsMassLoading, onFetchMass]);

    return {
        isOpen,
        setIsOpen,
        triggerOpen,
        triggerClose,
        isMassSelected,
        isMassLoading,
        toggleMassSelect,
        options,
        computedOptions,
        isOptionEqualToValue,
        setValue,
        resetValue,

        handleOnChange,
        handleDisableOnFilterOptions,
    };
};

/**
 * Infinite Autocomplete
 */

interface InfiniteAutocompleteProps<
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> extends Omit<
        AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
        'onChange' | 'renderInput' | 'getOptionKey' | 'getOptionLabel'
    > {
    pageSize?: number;
    mass?: boolean;
    onFetchMass?: () => Promise<ReadonlyArray<Value>>;
    error?: boolean;
    label?: string;
    required?: boolean;
    variant?: TextFieldProps['variant'];

    onChange: (value: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>) => void;
    onScroll?: () => void;
    onOpen?: () => void;
    onClose?: () => void;
    getOptionKey: (option: Value | AutocompleteFreeSoloValueMapping<FreeSolo>) => string | number;
    getOptionLabel: (option: Value | AutocompleteFreeSoloValueMapping<FreeSolo>) => string;
}

const InfiniteAutocomplete = <
    Value,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>({
    onFetchMass,
    pageSize = 20,
    mass = false,
    loading = false,
    getOptionKey,
    getOptionLabel,
    onScroll = () => {},
    onOpen = () => {},
    onClose = () => {},
    ...props
}: InfiniteAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>) => {
    const autocomplete: UseInfiniteAutocompleteState<Value, Multiple, DisableClearable, FreeSolo> =
        useInfiniteAutocomplete<Value, Multiple, DisableClearable, FreeSolo>({
            onFetchMass: onFetchMass,
            pageSize: pageSize,
            multiple: props.multiple,
            options: props.options,
            value: props.value as unknown as AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>,
            onChange: props.onChange,
            getOptionKey: getOptionKey,
            getOptionLabel: getOptionLabel,
        });
    const {
        isOpen,
        triggerOpen,
        triggerClose,
        isMassSelected,
        isMassLoading,
        toggleMassSelect,
        computedOptions,
        isOptionEqualToValue,
        resetValue,
        handleOnChange,
        handleDisableOnFilterOptions,
    } = autocomplete;

    const listboxRef = useRef<Element | null>(null);
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    useEffect(() => {
        if (isOpen) {
            onOpen();
        } else {
            onClose();
        }
    }, [isOpen, onOpen, onClose]);

    const handleOnScroll = useCallback(
        (event: React.SyntheticEvent): void => {
            const listboxNode: Element = event.currentTarget;
            const { scrollTop, scrollHeight, clientHeight } = listboxNode;

            setScrollPosition(listboxNode.scrollTop);

            if (scrollTop + clientHeight >= scrollHeight - 10 && !loading) {
                onScroll();
            }
        },
        [loading, onScroll]
    );

    const paperComponent = useCallback(
        ({ children, ...paperProps }) => {
            const valueLength: number = Array.isArray(props.value) ? props?.value?.length || 0 : 0;

            const getFormControlLabel = (): string => {
                if (isMassLoading) {
                    return 'Selecting all...';
                }
                return isMassSelected && valueLength > 0 ? `Selected ${valueLength}` : 'Select all';
            };

            return (
                <Paper {...paperProps}>
                    {mass && props.options.length > 0 && (
                        <>
                            <MenuItem
                                onMouseDown={(event) => event.preventDefault()}
                                onClick={(event) => {
                                    event.preventDefault();
                                    toggleMassSelect();
                                }}
                                sx={{ px: 3 }}
                            >
                                <FormControlLabel
                                    label={getFormControlLabel()}
                                    control={
                                        <Checkbox
                                            id="infinite-autocomplete-mass-select-checkbox"
                                            checked={isMassSelected}
                                            disabled={isMassLoading}
                                        />
                                    }
                                />
                            </MenuItem>
                            <Divider />
                        </>
                    )}
                    {children}
                </Paper>
            );
        },
        [mass, isMassSelected, isMassLoading, toggleMassSelect, props.options, props.value]
    );

    useEffect(() => {
        if (listboxRef.current) {
            listboxRef.current.scrollTop = scrollPosition; // Restore scroll position
        }
    }, [computedOptions, scrollPosition]);

    return (
        <Autocomplete
            {...props}
            loading={loading}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            filterOptions={handleDisableOnFilterOptions}
            options={computedOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleOnChange}
            onFocus={() => triggerOpen()}
            onBlur={() => triggerClose()}
            renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                    {...textFieldProps}
                    size={props.size}
                    error={props.error}
                    label={props.label}
                    variant={props.variant}
                    required={props.required}
                    InputProps={{
                        ...textFieldProps.InputProps,
                        endAdornment: (
                            <>
                                {isOpen && loading && <CircularProgress size={20} />}
                                {textFieldProps?.InputProps?.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(attributes, option, { selected }) => {
                if (!option) {
                    return null;
                }

                return (
                    <li {...attributes} key={getOptionKey(option)}>
                        {props.multiple && <Checkbox checked={selected} />}
                        {/*<ListItemText primary={getOptionLabel(option)} />*/}
                        {getOptionLabel(option)}
                    </li>
                );
            }}
            renderTags={(tagValue, getTagProps) => {
                if (isOpen) {
                    return null; // Hide tags when focused
                }

                const tagCount = tagValue.length;
                const getChipLabel = () => `${tagCount} Selected`;

                if (tagCount > 1) {
                    return (
                        <Chip
                            label={getChipLabel()}
                            size={props.size}
                            {...getTagProps({ index: 0 })}
                            key="infinite-autocomplete-tag-count-label"
                            onDelete={() => resetValue()}
                        />
                    );
                }

                return tagCount > 0 ? (
                    <Typography
                        sx={{
                            px: 1,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {getOptionLabel(tagValue[0])}
                    </Typography>
                ) : null;
            }}
            ListboxProps={{
                ref: listboxRef,
                onScroll: handleOnScroll,
            }}
            PaperComponent={paperComponent}
            sx={{
                '& .MuiAutocomplete-popupIndicator': {
                    color: 'action.active',
                },
                '& .MuiAutocomplete-inputRoot': {
                    height: props?.size === 'small' ? '2.5rem' : '3.5rem',
                    overflow: 'hidden',
                },
            }}
        />
    );
};

export type { UseInfiniteAutocompleteProps, UseInfiniteAutocompleteState };
export { useInfiniteAutocomplete };

export type { InfiniteAutocompleteProps };
export default InfiniteAutocomplete;
